<template>
  <div class="ons-verhaal">
    <subPageHero
      heroImage="images/Human_holding_bread.jpeg"
      :heroText="$root.t('Het begin')"
    />

    <main-container>
      <SectionTitleContainer
        title="Over ‘t Broodhuys : “Ambacht staat voorop” "
        light
        bold
      />
      
      <ContentContainer
        :content="{
          text:
            '‘t Broodhuys is overgenomen in 2010. Twee gepassioneerde werknemers, Peter en Mike, kregen de mogelijkheid de bakkerij over te nemen van Ko en Trudy Koenis. Peter werkte al jaren in het banket en Mike in het brood.',
        }"
        light
      />

      <ContentContainer
        :content="{
          image: 'images/customer/Bakkers.jpg',
        }"
        light
      />

      <blockquote class="quote">
        “Wij zien potentie in bakkerij ‘t Broodhuys.”
        <br />
        <small>— Mike & Peter</small>
      </blockquote>

      <ContentContainer
          class="pb-6"
        :content="{
          text:
            'Het bakken van de dagelijkse verse gebakjes en broden -wat vroeg in de nacht al start- is niet veranderd toen Peter en Mike eigenaren werden. “Wij kunnen onze passie op deze manier kwijt in alle facetten van de bakkerij”.<br/><br/>' +
            'De eerste stap was het verbouwen en vernieuwen van de bakkerij, door onder andere te investeren in het vernieuwen van de machines. Ook zijn de processen verbeterd. <br/><br/>' +
            'Het resultaat van deze investeringen mag er zijn, de kwaliteit van de ambachtsproducten is van topniveau.<br/><br/>',
        }"
        light
      />

      <section class="has-background-white pb-6">
        <SectionTitleContainer :title="$root.t('Ambacht')" />

        <ContentContainer
          :content="{
            text:
              'Onze bakkers werken nog op ambachtelijke wijze.<br/>Dit houdt in dat de bakkers midden in de nacht starten met het maken van de broden en banket en ’s morgens gaan de broden nog echt warm over de toonbank.\n',
            image: 'images/customer/traditional_way.png',
          }"
          reverse
        />
      </section>
    </main-container>
  </div>
</template>

<script>
import subPageHero from "../components/SubPageHero";
import MainContainer from "@/components/Container/MainContainer";
import ContentContainer from "@/components/Container/ContentContainer";
import SectionTitleContainer from "@/components/Container/SectionTitleContainer";

export default {
  name: "OnsVerhaal",
  components: {
    ContentContainer,
    SectionTitleContainer,
    MainContainer,
    subPageHero,
  },
  data() {
    return {};
  },
  created() {
    console.log("its created");
  },
  mounted() {
    // let self = this;
  },
};
</script>
