var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ons-verhaal"},[_c('subPageHero',{attrs:{"heroImage":"images/Human_holding_bread.jpeg","heroText":_vm.$root.t('Het begin')}}),_c('main-container',[_c('SectionTitleContainer',{attrs:{"title":"Over ‘t Broodhuys : “Ambacht staat voorop” ","light":"","bold":""}}),_c('ContentContainer',{attrs:{"content":{
        text:
          '‘t Broodhuys is overgenomen in 2010. Twee gepassioneerde werknemers, Peter en Mike, kregen de mogelijkheid de bakkerij over te nemen van Ko en Trudy Koenis. Peter werkte al jaren in het banket en Mike in het brood.',
      },"light":""}}),_c('ContentContainer',{attrs:{"content":{
        image: 'images/customer/Bakkers.jpg',
      },"light":""}}),_c('blockquote',{staticClass:"quote"},[_vm._v(" “Wij zien potentie in bakkerij ‘t Broodhuys.” "),_c('br'),_c('small',[_vm._v("— Mike & Peter")])]),_c('ContentContainer',{staticClass:"pb-6",attrs:{"content":{
        text:
          'Het bakken van de dagelijkse verse gebakjes en broden -wat vroeg in de nacht al start- is niet veranderd toen Peter en Mike eigenaren werden. “Wij kunnen onze passie op deze manier kwijt in alle facetten van de bakkerij”.<br/><br/>' +
          'De eerste stap was het verbouwen en vernieuwen van de bakkerij, door onder andere te investeren in het vernieuwen van de machines. Ook zijn de processen verbeterd. <br/><br/>' +
          'Het resultaat van deze investeringen mag er zijn, de kwaliteit van de ambachtsproducten is van topniveau.<br/><br/>',
      },"light":""}}),_c('section',{staticClass:"has-background-white pb-6"},[_c('SectionTitleContainer',{attrs:{"title":_vm.$root.t('Ambacht')}}),_c('ContentContainer',{attrs:{"content":{
          text:
            'Onze bakkers werken nog op ambachtelijke wijze.<br/>Dit houdt in dat de bakkers midden in de nacht starten met het maken van de broden en banket en ’s morgens gaan de broden nog echt warm over de toonbank.\n',
          image: 'images/customer/traditional_way.png',
        },"reverse":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }